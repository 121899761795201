<template>
  <AppListItem :analytics-name="analyticsName" v-on="$listeners">
    <template #figure>
      <slot v-if="!hideFigure" name="figure">
        <AppFigure class="xe-figure" theme="gray">
          <AppIcon>
            <slot name="figure-icon">
              <IconAdd />
            </slot>
          </AppIcon>
        </AppFigure>
      </slot>
    </template>
    <template #left>
      <slot name="left">
        <AppListItemTitle>
          {{ title }}
        </AppListItemTitle>
        <AppListItemCaption v-if="subTitle">{{ subTitle }}</AppListItemCaption>
      </slot>
    </template>
    <template v-if="!hideAction" #action>
      <slot name="action">
        <AppIcon>
          <slot name="action-icon">
            <IconChevronRight />
          </slot>
        </AppIcon>
      </slot>
    </template>
  </AppListItem>
</template>

<script>
import {
  AppIcon,
  AppListItemCaption,
  AppListItem,
  AppFigure,
  AppListItemTitle,
} from '@oen.web.vue2/ui'

import { IconAdd, IconChevronRight } from '@oen.web.vue2/icons'
import { useEnvStore } from '@galileo/stores'

export default {
  name: 'RiaListItem',
  components: {
    AppListItem,
    AppListItemTitle,
    AppListItemCaption,
    AppFigure,
    AppIcon,
    IconAdd,
    IconChevronRight,
  },
  emits: ['itemClick'],
  props: {
    analyticsName: {
      type: String,
      default: 'ria-list-item',
      required: () => {
        const envStore = useEnvStore()
        return !!envStore.env.VUE_APP_ANALYTICS_ENABLED
      },
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    subTitle: {
      type: String,
      required: false,
      default: '',
    },
    hideFigure: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideAction: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return {}
  },
}
</script>

<style scoped></style>
