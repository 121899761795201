<template>
  <div class="button-group">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ButtonGroup',
}
</script>

<style scoped>
.button-group {
  @apply flex flex-col;
}
::v-deep .button {
  @apply mb-2 mx-auto;
  :last-child {
    @apply mb-0;
  }
}
</style>
