<template>
  <AppList>
    <AppListItem @itemClick="select()">
      <template #figure>
        <AppFigure theme="blue">
          <AppIcon name=" ">
            <IconAdd />
          </AppIcon>
        </AppFigure>
      </template>

      <template #left>
        <AppListItemTitle>
          <h1>{{ text }}</h1>
        </AppListItemTitle>
      </template>

      <template #action>
        <div class="flex flex-row items-center">
          <div>
            <AppIcon name=" " class="recipient-action-icon">
              <IconChevronRight />
            </AppIcon>
          </div>
        </div>
      </template>
    </AppListItem>
  </AppList>
</template>

<script>
import { useI18nStore } from '@galileo/stores'
import { IconAdd, IconChevronRight } from '@oen.web.vue2/icons'

import {
  AppIcon,
  AppFigure,
  AppListItem,
  AppListItemTitle,
  AppListItemCaption,
  AppSpinnerBig,
  AppList,
} from '@oen.web.vue2/ui'

export default {
  name: 'XeAddListItem',
  components: {
    AppList,
    AppListItemCaption,
    AppListItemTitle,
    AppListItem,
    AppIcon,
    AppSpinnerBig,
    AppFigure,
    IconAdd,
    IconChevronRight,
  },
  props: {
    select: {
      type: Function,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { $t } = useI18nStore()

    return { $t }
  },
}
</script>
<style scoped>
::v-deep .list-item-title {
  @apply font-medium text-base;
  color: var(--theme-text, var(--theme-color-secondary, theme('colors.blue.text')));
}

::v-deep .list-item-caption {
  @apply text-xs font-normal text-tertiary-text;
}

::v-deep .figure {
  background-color: #f2f7fe;
  box-shadow: none;
}

.xe-figure {
  @apply font-semibold text-sm;
  color: var(--theme-color-primary, theme('colors.blue.default'));
}
</style>
