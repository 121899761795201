<template>
  <AppListItem
    class="payment-method-item"
    :analytics-name="`payment-method-item-${paymentMethod.id}`"
    :class="{
      'payment-method-item--send-money': isSendMoney,
      'payment-method-item--is-error': isError,
      'payment-method-item--is-disabled': isDisabled,
      'payment-method-item--is-expired': isExpired,
    }"
    @itemClick="itemSelected"
  >
    <template #figure>
      <AppFigure
        v-if="['Cheque', 'Savings'].includes(paymentMethod.type) && senderCountry === 'US'"
        class="xe-figure"
        theme="gray"
      >
        <IconACH />
      </AppFigure>

      <AppFigure
        v-else-if="['Cheque', 'Savings'].includes(paymentMethod.type) && senderCountry === 'CA'"
        class="xe-figure"
        theme="gray"
      >
        <IconEFT />
      </AppFigure>

      <AppFigure v-else class="xe-figure" theme="gray">
        <img
          v-if="paymentMethod.image"
          alt="Payment Method"
          :src="require(`@galileo/assets/images/${paymentMethod.image}`)"
        />
      </AppFigure>
    </template>

    <template #left>
      <AppListItemTitle v-if="isSendMoney" class="payment-method-item-title-container">
        <span
          v-if="
            paymentMethod.paymentMethod !== PAYMENT_METHODS.CREDIT_CARD &&
            paymentMethod.paymentMethod !== PAYMENT_METHODS.DEBIT_CARD
          "
          class="payment-title"
        >
          {{ paymentMethod.title }}
        </span>
        <span class="payment-number">{{ maskedAccountNumberDotsReplaced }}</span>
      </AppListItemTitle>

      <AppListItemTitle v-else class="payment-method-item-title-container">
        <template
          v-if="
            paymentMethod.paymentMethod !== 'CreditCard' &&
            paymentMethod.paymentMethod !== 'DebitCard'
          "
        >
          <span class="payment-title">{{ paymentMethod.title }} </span>
          <span class="payment-number">{{ maskedAccountNumberDotsReplaced }}</span>
        </template>
        <template v-else>
          <span>{{ maskedAccountNumberDotsReplaced }}</span>
        </template>
      </AppListItemTitle>

      <!-- TODO - paymentObj.status produces console warnings inspect further to remove -->
      <AppListItemCaption v-if="isExpired" class="payment-method-item-caption">
        {{ $t('ComponentPaymentMethod.CardExpiredText').value }}
      </AppListItemCaption>

      <AppListItemCaption v-else-if="isDisabled" class="payment-method-item-caption">
        {{ $t('ComponentPaymentMethod.StatusAccountDisabled').value }}
      </AppListItemCaption>

      <AppListItemCaption
        v-else-if="paymentMethod.accountType === 'Cheque'"
        class="payment-method-item-caption"
      >
        {{ $t('ComponentPaymentMethod.AccountTypeChecking').value }}
      </AppListItemCaption>

      <AppListItemCaption
        v-else-if="paymentMethod.accountType === 'Savings'"
        class="payment-method-item-caption"
      >
        {{ $t('ComponentPaymentMethod.AccountTypeSavings').value }}
      </AppListItemCaption>

      <AppListItemCaption
        v-else-if="isNotVerified"
        class="payment-method-item-caption not-verified"
      >
        {{
          isSendMoney
            ? $t('ComponentPaymentMethod.StatusAccountNotVerified').value
            : $t('ComponentPaymentMethod.StatusAccountPendingVerification').value
        }}
      </AppListItemCaption>

      <AppListItemCaption
        v-else-if="paymentMethod.paymentMethod === 'CreditCard'"
        class="payment-method-item-caption"
      >
        {{ $t('ComponentPaymentMethod.TextExpiresOn').value }} {{ cardExpirationDate }}
      </AppListItemCaption>
      <AppListItemCaption v-else class="payment-method-item-caption">
        {{ paymentMethod.alias }}
      </AppListItemCaption>
    </template>
    <template #action>
      <AppIcon v-if="isOverrideActionMenu" class="payment-method-action-icon">
        <IconChevronRight />
      </AppIcon>

      <AppMenu
        v-else-if="paymentObj.menuItems.length"
        class="payment-method-action-icon"
        append-to="body"
        analytics-name="payment-method-menu"
      >
        <AppMenuItem
          v-for="(menuItem, index) in paymentObj.menuItems"
          :key="index"
          :label="$t(menuItem.labelKey).value"
          :analytics-name="'payment-method-menu-item-' + menuItem.eventName"
          @click="$emit(menuItem.eventName, paymentMethod)"
        />
      </AppMenu>

      <AppInputRadioButtons
        v-else-if="isExpired"
        :value="isExpired ? '1' : '0'"
        :options="[
          {
            value: '1',
            disabled: isExpired,
          },
        ]"
      >
      </AppInputRadioButtons>

      <AppIcon v-else class="payment-method-action-icon">
        <IconChevronRight />
      </AppIcon>
    </template>
  </AppListItem>
</template>

<script>
import { computed } from '@vue/composition-api'

import { IconChevronRight, IconACH, IconEFT } from '@oen.web.vue2/icons'
import { usePaymentInfo } from '@galileo/components/Views/PaymentMethod/usePaymentHelper'
import BankAccount from '@galileo/models/Payment/BankAccount'
import CardAccount from '@galileo/models/Payment/CardAccount'

import {
  AppListItem,
  AppListItemCaption,
  AppIcon,
  AppMenu,
  AppMenuItem,
  AppListItemTitle,
  AppFigure,
  AppInputRadioButtons,
} from '@oen.web.vue2/ui'

import { useAuthStore, useI18nStore } from '@galileo/stores'
import { PAYMENT_METHODS } from '@galileo/constants/sendMoneyFlow.const'

export default {
  name: 'PaymentMethod',
  components: {
    AppListItem,
    AppIcon,
    AppMenu,
    AppMenuItem,
    AppFigure,
    AppListItemCaption,
    AppListItemTitle,
    IconChevronRight,
    IconACH,
    IconEFT,
    AppInputRadioButtons,
  },
  props: {
    paymentMethod: {
      type: Object,
      required: true,
      validator: (payment) => payment instanceof CardAccount || payment instanceof BankAccount,
    },
    isSendMoney: {
      type: Boolean,
      default: false,
    },
    isOverrideActionMenu: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const authStore = useAuthStore()
    const { isNotVerified, isDisabled, isExpired, isError, paymentObj } = usePaymentInfo(
      props.paymentMethod,
      props.isSendMoney
    )
    const maskedAccountNumberDotsReplaced = computed(() => {
      if (props.paymentMethod.maskedAccountNumber) {
        return props.paymentMethod.maskedAccountNumber.replace('....', '.... ').replaceAll('.', '•')
      }
    })

    const cardExpirationDate = computed(() => {
      if (props.paymentMethod.accountExpirationMonth && props.paymentMethod.accountExpirationYear) {
        return `${String(props.paymentMethod.accountExpirationMonth).padStart(2, '0')}/${
          props.paymentMethod.accountExpirationYear
        }`
      }
    })

    const senderCountry = authStore.userProfile?.country

    const itemSelected = () => {
      if (isExpired.value) {
        emit('expired', props.paymentMethod)
      } else if (isDisabled.value) {
        emit('disabled', props.paymentMethod)
      } else if (paymentObj.value.onSelect) {
        emit(paymentObj.value.onSelect, props.paymentMethod)
      }
    }

    return {
      isNotVerified,
      isDisabled,
      isExpired,
      isError,
      paymentObj,
      maskedAccountNumberDotsReplaced,
      cardExpirationDate,
      senderCountry,
      $t,
      itemSelected,
      PAYMENT_METHODS,
    }
  },
}
</script>

<style scoped>
.payment-method-item {
  &.payment-method-item--is-error {
    .payment-method-item-caption {
      @apply text-red-text;
    }
  }
  &.payment-method-item--is-expired {
    .payment-method-item-caption {
      @apply text-red-text;
    }
  }
  &.payment-method-item--is-disabled {
    .payment-method-item-caption {
      @apply text-red-text;
    }
  }
  &.payment-method-item--send-money {
    &.payment-method-item--is-disabled,
    &.payment-method-item--is-expired {
      .payment-method-item-title-container {
        @apply text-tertiary-text;
      }
      .payment-method-item-caption {
        @apply text-red-text;
      }
    }
    .payment-method-item-figure-icon {
      @apply text-tertiary-text bg-white;

      img {
        @apply opacity-30;
      }
    }
    .payment-method-item-title-container {
      @apply font-medium leading-5 text-base;
    }
    .payment-method-item-caption {
      @apply font-normal leading-4 text-xs;
    }
  }
}

::v-deep .list-item-caption {
  @apply text-xs font-normal text-tertiary-text;
}

.payment-method-action-icon {
  /* TODO check removal of  w-12 doesnt break truncated items*/
  @apply text-tertiary-text align-middle;
}

.payment-method-item-title-container {
  @apply inline-grid !important;
  @apply grid-flow-col;
}

.payment-title {
  @apply truncate;
  @apply capitalize;
  /* ellipsis on overlapping text */
}

.payment-number {
  @apply pl-1;
}
.not-verified {
  color: rgba(217, 62, 10, 1) !important;
}
</style>
