<template>
  <div class="methods-container">
    <AppCard sheet :class="isSendMoney ? 'methods-empty' : 'methods-empty-payment'">
      <template #header>
        <XeStepperCardHeader
          :emitBack="true"
          analytics-name="xe-send-money-no-payment-method-back"
          @back="$emit('back')"
        />
      </template>

      <AppCardImageTitle
        class="image"
        :src="require('@galileo/assets/images/illustrations/money-wings.svg')"
        alt="Payment option"
        :title="`${$t('ComponentNoPaymentMethodsSendMoney.Title').value}`"
      >
        <div class="description-text">
          <p v-if="isCorporate">{{ $t('ComponentNoPaymentMethods.CorporateDescription').value }}</p>
          <p v-else>{{ $t('ComponentNoPaymentMethodsSendMoney.Description').value }}</p>
        </div>
      </AppCardImageTitle>

      <ButtonGroup>
        <AppButton
          v-if="isCorporate"
          class="btn-add-corp"
          theme="primary"
          @click="$emit('addPaymentMethod')"
        >
          {{ $t('AccountPaymentMethods.AddPaymentMethodButton').value }}
        </AppButton>
        <AppButton
          v-else
          class="no-payment-methods-button"
          theme="secondary"
          analytics-name="no-payment-methods-add"
          @click="$emit('addPaymentMethod')"
        >
          <template #leftIcon>
            <AppIcon name=" ">
              <IconAdd />
            </AppIcon>
          </template>
          {{ $t('ComponentNoPaymentMethods.ButtonAdd').value }}
        </AppButton>
      </ButtonGroup>
    </AppCard>
  </div>
</template>

<script>
import { useI18nStore, useAuthStore, useSendMoneyStore } from '@galileo/stores'
import { useRouter } from '@galileo/composables/useRouter'
import { IconAdd } from '@oen.web.vue2/icons'
import ButtonGroup from '@galileo/components/ButtonGroup/ButtonGroup'
import XeStepperCardHeader from '@galileo/components/XeStepperCardHeader/XeStepperCardHeader'

import { AppCard, AppCardImageTitle, AppButton, AppIcon } from '@oen.web.vue2/ui'

export default {
  name: 'NoPaymentMethods',
  components: {
    AppButton,
    AppCard,
    AppCardImageTitle,
    ButtonGroup,
    IconAdd,
    AppIcon,
    XeStepperCardHeader,
  },
  props: {
    isSendMoney: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['addPaymentMethod', 'back'],
  setup() {
    const { $t } = useI18nStore()
    const router = useRouter()

    const isCorporate = useAuthStore().isCorporateAccount

    const sendMoney = () => {
      useSendMoneyStore().restoreDefaultForm()
      router.push('/send-money')
    }

    return {
      sendMoney,
      $t,
      isCorporate,
    }
  },
}
</script>

<style scoped>
.methods-container {
  .methods-empty {
    @apply border-blue-lighter border-solid border;
    ::v-deep .card-content {
      @apply py-4;
    }
  }
  .methods-empty-payment {
    ::v-deep .card-content {
      @apply py-12;
    }
  }
  .methods-empty-payment.card--has-image-title.card--has-header {
    ::v-deep .card-content {
      @apply mt-0;
    }
    ::v-deep .card-content-block {
      background-color: red;
      @apply mb-0;
    }
    ::v-deep .card-image-title-image {
      @apply pb-8;
    }
  }
  .image {
    ::v-deep .card-image-title-image {
      @apply shadow-ria-1;
      padding: 1.5rem;
      border-radius: 50%;
    }
  }
  .no-payment-methods-button {
    @apply w-auto !important;
    @apply mx-auto;
    @apply bg-blue-light;
    ::v-deep .button-inner {
      @apply py-2;
    }
  }
  .btn-add-corp {
    width: 100%;
  }
  ::v-deep .card-content-block {
    @apply mb-6;
  }
  .description-text {
    @apply mb-7 mt-5;
    @apply text-base;
  }
}
</style>
