import { computed } from '@vue/composition-api'
import BankAccount from '@galileo/models/Payment/BankAccount'
import CardAccount from '@galileo/models/Payment/CardAccount'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '@galileo/stores'
/*
   1. Configure Types (menuItems,onSelect,Status)
   2. Setup Payment Method Flags
   3. Determine Type
*/

class MenuItem {
  constructor(id, labelKey) {
    this.id = id
    this.labelKey = labelKey
    this.eventName = events[id]
  }
}

class PaymentMethod {
  constructor(menuItems = [], onSelectEvent = undefined, status) {
    this.menuItems = menuItems
    this.onSelect = onSelectEvent
    this.status = status // TODO - Status producing console warnings
  }
}

export const events = {
  help: 'help',
  verify: 'verify',
  delete: 'delete',
  details: 'details',
  select: 'select',
  disabled: 'disabled',
}

// $t('ComponentPaymentMethod.StatusDisabled')
// $t('ComponentPaymentMethod.StatusNotVerified')
// $t('ComponentPaymentMethod.StatusExpired')
const statuses = {
  disabled: 'ComponentPaymentMethod.StatusDisabled',
  notVerified: 'ComponentPaymentMethod.StatusNotVerified',
  expired: 'ComponentPaymentMethod.StatusExpired',
}

// $t('ComponentPaymentMethod.MenuGetHelp')
// $t('ComponentPaymentMethod.MenuVerify')
// $t('ComponentPaymentMethod.MenuDelete')
// $t('ComponentPaymentMethod.MenuDetails')
const helpPayment = new MenuItem('help', 'ComponentPaymentMethod.MenuGetHelp')
let verifyPayment = new MenuItem('verify', 'ComponentPaymentMethod.MenuVerify')

const deletePayment = new MenuItem('delete', 'ComponentPaymentMethod.MenuDelete')
const detailsPayment = new MenuItem('details', 'ComponentPaymentMethod.MenuDetails')

export function usePaymentInfo(payment, isSendMoney = false) {
  const authStore = useAuthStore()

  const { isCorporateAccount } = storeToRefs(authStore)

  if (isCorporateAccount.value) {
    verifyPayment = new MenuItem('verify', 'ComponentPaymentMethod.MenuDetails')
  }

  //[TODO] fix validation for  card accounts
  // only BankAccount or CardAccount type allowed
  if (!(payment instanceof BankAccount) && !(payment instanceof CardAccount)) {
    // eslint-disable-next-line no-console
    console.error(
      `Incorrect type of payment method. It must be ${BankAccount.name} or ${CardAccount.name}`
    )
  }

  // 1.Configure Types

  // ********* Active account *************
  // TODO - payment.subTitle is used as a translation key, but value supplied is not translation key review and update usage
  const AccountActiveSMF = new PaymentMethod([], events.select, payment.subTitle)
  const AccountActive = new PaymentMethod(
    [detailsPayment, deletePayment],
    events.details,
    payment.subTitle
  )

  // ********* Disabled account *************
  const AccountDisabled = new PaymentMethod([helpPayment], events.disabled, statuses.disabled)
  const AccountDisabledSMF = new PaymentMethod([helpPayment], null, statuses.disabled)

  // ********* Bank Account Not Verified **********
  const BankAccountNotVerified = new PaymentMethod(
    [verifyPayment, deletePayment],
    events.verify,
    statuses.notVerified
  )
  const BankAccountNotVerifiedSMF = new PaymentMethod([], events.verify, statuses.notVerified)

  // ********* Card Account Expired **********
  const CardAccountExpired = new PaymentMethod(
    [detailsPayment, deletePayment],
    events.details,
    statuses.expired
  )
  const CardAccountExpiredSMF = new PaymentMethod([deletePayment], null, statuses.expired)

  // 2. Setup Flags
  const isDisabled = computed(() => {
    return payment.disabled
  })

  const isBankAccount = computed(() => {
    return payment instanceof BankAccount
  })

  const isDebitCard = computed(() => {
    return payment instanceof CardAccount || payment.isCard ? payment.isDebitCard : false
  })

  const isCreditCard = computed(() => {
    return payment instanceof CardAccount || payment.isCard ? !payment.isDebitCard : false
  })

  const isCard = computed(() => {
    return isCreditCard.value || isDebitCard.value
  })

  const verificationStatus = computed(() => {
    return payment instanceof BankAccount ? payment.verificationStatus : null
  })

  const isNotVerified = computed(() => {
    return !isCard.value && !(isBankAccount.value && verificationStatus.value === 'Verified')
  })

  const isExpired = computed(() => {
    return payment.isExpired
  })

  const isError = computed(() => {
    return (
      isDisabled.value ||
      (isBankAccount.value && isNotVerified.value) ||
      (isCard.value && isExpired.value)
    )
  })

  // 3. Determine Type
  const paymentObj = computed(() => {
    if (isDisabled.value) {
      return isSendMoney ? AccountDisabledSMF : AccountDisabled
    } else if (isCard.value && isExpired.value) {
      return isSendMoney ? CardAccountExpiredSMF : CardAccountExpired
    } else if (isBankAccount.value && isNotVerified.value) {
      return isSendMoney ? BankAccountNotVerifiedSMF : BankAccountNotVerified
    } else {
      return isSendMoney ? AccountActiveSMF : AccountActive
    }
  })

  return {
    isDisabled,
    isBankAccount,
    isCard,
    isDebitCard,
    isCreditCard,
    isNotVerified,
    isExpired,
    isError,
    verificationStatus,
    paymentObj,
  }
}
