<template>
  <AppList class="payment-methods-list">
    <!-- Loop through payment methods -->
    <PaymentMethod
      v-for="paymentMethod in paymentMethods"
      :key="paymentMethod.id"
      :analytics-name="'payment-methods-item-' + paymentMethod.id"
      :is-override-action-menu="isOverrideActionMenu"
      :payment-method="paymentMethod"
      :is-send-money="isSendMoney"
      @help="onHelp"
      @verify="onVerify"
      @delete="onDelete"
      @details="onDetails"
      @expired="onExpired"
      @disabled="onDisabled"
      @select="$emit(events.select, $event)"
    />
  </AppList>
</template>

<script>
import RiaListItem from '@galileo/components/RiaListItem/RiaListItem'
import PaymentMethod from '@galileo/components/Views/PaymentMethod/PaymentMethod'
import BankAccount from '@galileo/models/Payment/BankAccount'
import CardAccount from '@galileo/models/Payment/CardAccount'
import { useRouter } from '@galileo/composables/useRouter'

import { events } from '@galileo/components/Views/PaymentMethod/usePaymentHelper'
import { useAddPaymentMethods } from '@galileo/composables/usePaymentHelpers'

import { AppList } from '@oen.web.vue2/ui'

import { usePaymentsStore, useI18nStore, useAppStore, useAnalyticsStore } from '@galileo/stores'
import { SEGMENT_EVENTS, SEGMENT_LOCATIONS, SEGMENT_PAYMENT_CARD_STATUS } from '@galileo/constants/segmentAnalytics'

export default {
  name: 'PaymentMethods',
  components: {
    AppList,
    RiaListItem,
    PaymentMethod,
  },
  emits: ['select', 'add', 'loaded'],
  props: {
    paymentMethods: {
      type: Array,
      default: () => [],
      // throwing errors [TODO] Uncomment and solve
      validator: (payments) =>
        payments.every((ele) => ele instanceof CardAccount || ele instanceof BankAccount),
    },
    loadPaymentMethods: {
      type: Boolean,
      default: true,
    },
    isSendMoney: {
      type: Boolean,
      default: false,
    },
    isOverrideActionMenu: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const router = useRouter()
    const { $t } = useI18nStore()
    const appStore = useAppStore()
    const analyticsStore = useAnalyticsStore()

    const selectPaymentMethod = (paymentMethod) => {
      emit('select', paymentMethod)
    }

    const { addPaymentMethod } = useAddPaymentMethods(props.isSendMoney)

    const paymentsStore = usePaymentsStore()

    const onDetails = (payment) => {
      // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      // if (payment instanceof CardAccount) {

      // }
      paymentsStore.setSelectedPaymentMethod(payment)

      if (payment instanceof BankAccount) {
        router.push({ path: 'bank-details', append: true })
      } else {
        router.push({ path: 'card-details', append: true })
      }
    }
    const onExpired = (payment) => {
      paymentsStore.setSelectedPaymentMethod(payment)

      analyticsStore.track({
        event: SEGMENT_EVENTS.PAYMENT_METHOD_EXPIRED,
        traits: {
          paymentMethod: payment.paymentMethod,
          paymentMethodStatus: SEGMENT_PAYMENT_CARD_STATUS.EXPIRED,
          location: props.isSendMoney ? SEGMENT_LOCATIONS.SEND_MONEY : SEGMENT_LOCATIONS.ACCOUNT,
        },
      })

      router.push({ path: 'expired', append: true })
    }

    const onDisabled = (payment) => {
      paymentsStore.setSelectedPaymentMethod(payment)
      router.push({ path: 'disabled', append: true })
    }

    const onDelete = (payment) => {
      paymentsStore.setSelectedPaymentMethod(payment)
      router.push({ path: 'delete', append: true })
    }

    const onHelp = (payment) => {
      paymentsStore.setSelectedPaymentMethod(payment)
      appStore.openHelpDesk({ loadingRef: ref(false) })
    }

    const onVerify = (payment) => {
      paymentsStore.setSelectedPaymentMethod(payment)
      router.push({ path: 'not-verified', append: true })
    }

    return {
      onExpired,
      selectPaymentMethod,
      addPaymentMethod,
      events,
      onDetails,
      onDelete,
      onDisabled,
      onHelp,
      onVerify,
      $t,
    }
  },
}
</script>
