<template>
  <!-- Renders AppModal, children routes to be configured in vue router -->
  <RouterView :value="isModalVisible" @input="hideModal"> </RouterView>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useRouter } from '@galileo/composables/useRouter'
export default {
  name: 'ModalRouterView',
  components: {},

  setup() {
    const router = useRouter()
    const isModalVisible = computed(() => {
      return !!router.currentRoute.meta.modal?.showModal
    })

    const hideModal = () => {
      router.go(-1)
    }

    return {
      isModalVisible,
      hideModal,
    }
  },
}
</script>
